html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;

  background-color: #f0f0f0;
  color: #222;
}

a,
a:visited {
  color: #0064ff;
}

.page {
  position: relative;
  margin: 0 80px;
  min-width: 300px;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 330px;
  padding-bottom: 90px;

  background-image: url(img/header.jpg);
  background-color: #333;
  background-position: center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}

.header__logo {
  width: 287px;
}

.header__portal-link,
.header__portal-link:visited {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 14px;
}

.header__portal-link:active,
.header__portal-link:hover {
  color: #ff6400;
}

.header-links {
  height: 100px;
  text-align: right;
  line-height: 100px;
}

.main-copy {
  margin-top: 140px;
  padding: 40px;
  background-color: #fff;
  border-bottom: solid 10px #ff6400;
}

.main-copy__title {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.main-copy__content {
  line-height: 1.5;
  margin: 12px 0 18px;
}

.spacing-after {
  margin-bottom: 36px;
}

.main-copy__contact {
  line-height: 1.55;
  font-size: 16px;
  margin: 12px 0 18px;
}

.main-copy__type {
  line-height: 200%;
}

@media (max-width: 900px) {
  .header__portal-link {
    font-size: 12px;
  }

  .page {
    margin-left: 20px;
    margin-right: 20px;
  }

  .header-links {
    line-height: 60px;
  }
}

@media (max-width: 600px) {
  .page {
    margin: 0;
    font-size: 16px;
  }

  .header {
    height: 160px;
    padding-bottom: 0;
  }

  .main-copy {
    margin-top: 60px;
    padding: 24px;
  }

  .header-links {
    line-height: 30px;
    padding-right: 10px;
  }

  .header__portal-link {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .page {
    width: 1040px;
    margin-left: auto;
    margin-right: auto;
  }
}
